@import '../../styles/common/variables.scss';

.specialPriceLabelContainer {
    // position: relative;
    position: absolute;
    width: 100%;
    height: 100%;
}

.specialPriceLabel {
    position: absolute;
    background-color: $secondary-orange-400;
    border-radius: 9999px;
    padding: 5px 20px;
    color: $accent-white;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap; // Prevents text from wrapping to the next line
    bottom: -10px;
    margin-bottom: 0;
}

.imageContainer {
    div {
        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}
@import '../../styles/common/variables.scss';
.hamburger {
    position: relative;
    width: 40px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 9999px;
    border: 1px solid #fff;
    cursor: pointer;
    z-index: 1002;
    /* Ensure it stays above the menu */
}

.bar {
    width: 16px;
    height: 2px;
    background-color: #fff;
    margin: 3px 0;
    transition: all 0.3s ease;
    position: absolute;
}

.topBar {
    top: 9px;
    transform-origin: center;
}

.middleBar {
    top: 16px;
    opacity: 1;
}

.bottomBar {
    top: 23px;
    transform-origin: center;
}

.hamburger.open .topBar {
    transform: rotate(45deg) translate(5px, 5px);
}

.hamburger.open .middleBar {
    opacity: 0;
}

.hamburger.open .bottomBar {
    transform: rotate(-45deg) translate(5px, -5px);
}

.mobileMenu {
    position: fixed;
    top: 80px;
    left: 0;
    width: 100%;
    height: 0;
    overflow: hidden;
    background-color: #2d3748;
    /* Adjust to your desired background color */
    transition: height 0.3s ease;
    z-index: 1000;
    /* Ensure it stays below the hamburger icon */
}

.mobileMenu.open {
    height: auto;
}

.mobileMenu ul {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.myPagesButton {
    z-index: 1002;
    padding: 11px 18px;

    @media (max-width: 768px) {
        padding: 11px 10px;
    }
}

.backgroundShield {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1001;
    height: 5rem;
    background-color: $primary-green-400;
}
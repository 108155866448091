@import '../../styles/common/variables.scss';

.link {
    width:40px;
    height: 40px;
    border: 1px solid #fff;
    border-radius: 50%;
    font-size: unset;
    line-height: unset;
    padding: unset;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon {
    width: 16px;
    height: 16px;
    max-width: 16px;
    max-height: 16px;
}

.sliderButton {
    height: 52px;
    width: 52px;
    background-color: #FFFFFF;
    border-radius: 999px;
    text-decoration: none !important;
    color: #000000;
    display: inline-block;
    background: no-repeat center center;

    &:hover {
        background-color: $secondary-black-500;
        color: $accent-white;
    }

    &:active {
        background-color: $secondary-black-400;
    }

    @media (max-width: $screen-md) {
        height: 40px;
        width: 40px;
    }

}

.sliderButtonLeft {
    background-image: url("/assets/img/icons/icon_chevron_left.svg");
    background-color: #ffffff;

    &:hover {
        background-image: url("/assets/img/icons/icon_chevron_left_white.svg");
    }
}

.sliderButtonRight {
    background-image: url("/assets/img/icons/icon_chevron_right.svg");
    background-color: #ffffff;

    &:hover{
        background-image: url("/assets/img/icons/icon_chevron_right_white.svg");
    }
}

.facilityAccordion {
    border: 1px solid $secondary-black-200;
}
@import '../../../styles/common/variables.scss';
@import '../../../styles/common/mixins.scss';

.newsBlock {
    border-radius: 1rem;
    display: flex;
    font-size: 14px;
    align-items: center;
    background-color: $secondary-orange-400;

    .contentLink {
        @include font-standard;
        color: $accent-white;
        padding: 0.5rem;
        height: 100%;
        width: 100%;
        align-items: center; 

        &:hover {
            .campaignReadMore {
                text-decoration: none;
            }
        }
    }

    .newsNews {
        font-size: 12px;
        padding: 0px 5px;
        height: 22px;
        border-radius: 8px;
        text-align: center;
        line-height: 22px;
        background-color: $accent-white;
        text-transform: uppercase;
        font-weight: bold;
        color: $secondary-black-400;
    }

    .newsText {
        @include font-standard;
        color: $accent-white;
    }

    .newsReadMore {
        text-decoration: underline;
        font-weight: bold;
    }
}
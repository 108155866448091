@import '../../../styles/common/variables.scss';

.myPagesPage {
    background-color: $background-color-order-green;
}

.titleWithIcon {
    position: relative;
    padding-left: 100px; // Adjust the padding to make space for the icon

    &:before {
        content: '';
        display: inline-block;
        background-image: url('/assets/img/icons/ikon_person.svg');
        background-size: contain;
        background-repeat: no-repeat;
        width: 80px; 
        height: 60px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}
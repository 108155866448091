@import '../../styles/common/variables.scss';

.link {
    width: 40px;
    height: 40px;
    border: 1px solid #fff;
    border-radius: 50%;
    font-size: unset;
    line-height: unset;
    padding: unset;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon {
    width: 16px;
    height: 16px;
    max-width: 16px;
    max-height: 16px;
}

.sliderContainer {
    display: flex;
    overflow: hidden;
    width: 100%;
}

.sliderWrapper {
    display: flex;
    transition: transform 0.5s ease-in-out;
    width: 100%;
}

.slide {
    flex: 1 0 100%;
    display: flex;
    justify-content: center;
    align-items: stretch; /* Ensure all items stretch to the same height */
}

.slideContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
@import '../../../styles/common/variables.scss';

.imageContainer {
    position: relative;
    width: 100%;

    @media (max-width: $screen-sm) {
        max-height: 200px;
        height: 200px;
    }

    @media (min-width: $screen-sm) and (max-width: $screen-md) {
        max-height: 300px;
        height: 300px;
    }

    @media (min-width: $screen-md) and (max-width: $screen-lg) {
        max-height: 420px;
        height: 420px;
    }

    @media (min-width: $screen-lg) {
        padding-top: 0;
    }

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
@import '../../../styles/common/variables.scss';
@import '../../../styles/common/mixins.scss';

.contentColumnBlock {
    a {
        @include standalone-link;
        text-decoration: underline;
        color: $secondary-black-400;
        position: relative;
        // padding-left: 36px;
        min-height: 32px;
        padding-left: calc($chevron-width + $chevron-padding + $outside-horisontal-padding);
        padding-right: $outside-horisontal-padding;

        &::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: $chevron-width;
            height: 24px;
            border-radius: 4px;
            background-size: 16px 16px;
            background-position: center;
            background-repeat: no-repeat;
            left: $outside-horisontal-padding;
            background-color: $primary-green-400 !important;
            background-image: url('/assets/img/icons/chevron.svg');
        }

        &:hover::before {
            background-color: $primary-green-300 !important;
        }

        &:focus::before {
            background-color: $primary-green-400 !important;
        }

        &:active::before {
            background-color: $primary-green-500 !important;
        }

        &:disabled::before,
        &[aria-disabled="true"]::before {
            opacity: 0.6;
        }
    }
}
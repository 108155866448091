@import 'mixins';
@import 'variables';

.header {
  position: fixed;
  width: 100%;
  z-index: 1500;
  // height: 5rem;
  top: 0;

  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .important-message {
    @include font-standard;
    color: $accent-white;

    @media (max-width: $screen-lg) {
      line-height:normal;
    }

    .title {
      font-size: 20px;
      font-weight: bold;

      @media (max-width: $screen-lg) {
        font-size: 14px;
      }
    }

    .text {
      font-size: 16px;

      @media (max-width: $screen-lg) {
        font-size: 14px;
      }
    }

    .link {
      @media (max-width: $screen-lg) {
        font-size: 14px;
      }
    }
  }
}

.header-ul {
  justify-content: space-between;
  gap: 1.5rem;
  margin: 0;
  padding: 0;
}

.navbar a {
  padding: 1rem;
  text-decoration: none;
  font-size: 14px;
}

.logo {
  z-index: 1002;
  /* Ensure the logo stays above the mobile menu */
  flex-shrink: 1;
  max-width: calc(100% - 151px);
  /* Ensure the logo doesn't take more space than available */
  padding-right: 1rem;

  img {
    max-width: 100%;
  }
}
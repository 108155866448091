$image-width: 580;
$image-height: 435;
$aspect-ratio: ($image-height / $image-width) * 100%;

.imageBlock {
    width: 100%;
    position: relative;
    padding-top: #{$aspect-ratio};
}

.imageButton {
    cursor: pointer;
    border: none;
    background: none;
    padding: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.image {
    object-fit: cover;
    object-position: center;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
}